import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby';

import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import type { NotFoundPageData } from 'types';
interface NotFoundPageProps {
  data: NotFoundPageData;
}

const NotFoundPage: FC<NotFoundPageProps> = ({ data }) => {
  useEffect(() => {
    navigate('/');
  }, []);

  return (
    <ContentProvider data={data}>
      <h1>404: Not Found</h1>
    </ContentProvider>
  );
};

export const query = graphql`
  query NOT_FOUND_PAGE_QUERY($locale: String!) {
    socialIcons: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SocialIcon
      }
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        ...CategoryElement
      }
    }
  }
`;

export default NotFoundPage;
